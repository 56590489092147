import React, { useEffect, useRef, useState } from 'react';
import css from './InputAutoSuggest.module.scss';
import classnames from 'classnames';
import { useFormikContext } from 'formik';
import { UseQueryResult } from 'react-query';
import { IInputProps, Input } from '../Input/Input';
import { List } from 'components/DropdownList/DropdownList';

export interface AutoSuggestQueryParams {
	searchTerm: string;
	pageNumber: number;
	pageSize: number;
}

type TSuggestionSelector = (data: any, queryInput?: AutoSuggestQueryParams) => any;

type AutoSuggestProps = {
	useQuery: (...any: any) => UseQueryResult<any, unknown>;
	dataSelector: TSuggestionSelector;
	onSelectOption?: (selectedValue: any) => void;
	labelPropName: string;
	valuePropName: string;
	isOpen?: boolean;
} & IInputProps;

export const InputAutoSuggest = ({
	name,
	useQuery,
	dataSelector,
	onSelectOption,
	labelPropName,
	valuePropName,
	className,
	formikProps,
	isOpen: initialOpen,
	...remainingProps
}: AutoSuggestProps) => {
	const { setFieldValue, isSubmitting } = useFormikContext();

	const [queryProps, setQueryProps] = useState<AutoSuggestQueryParams>({
		searchTerm: '',
		pageNumber: 1,
		pageSize: 10000000
	});

	const { data } = useQuery(queryProps);
	const selectedData = dataSelector(data, queryProps) as any[];

	const [localSearchTerm, setLocalSearchTerm] = useState(''); // Local input state
	const [isDropdownShown, setDropdownShown] = useState(initialOpen || false);

	const dropdownRef = useRef<HTMLDivElement>(null);
	const buttonRef = useRef<HTMLInputElement>(null);

	// Local clear function
	const clear = () => {
		setLocalSearchTerm('');
		setQueryProps(prev => ({ ...prev, searchTerm: '' }));
		setFieldValue(name, '');
		setDropdownShown(false);
	};

	// React to form submission events
	useEffect(() => {
		if (!isSubmitting) {
			clear();
		}
	}, [isSubmitting]); // eslint-disable-line

	useEffect(() => {
		setDropdownShown(!!initialOpen);
	}, [initialOpen]);

	useEffect(() => {
		const handleDocumentClick = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node) &&
				!buttonRef.current?.contains(event.target as Node)
			) {
				setDropdownShown(false);
			}
		};

		if (isDropdownShown) {
			document.addEventListener('click', handleDocumentClick);
		} else {
			document.removeEventListener('click', handleDocumentClick);
		}

		return () => {
			document.removeEventListener('click', handleDocumentClick);
		};
	}, [isDropdownShown]);

	return (
		<div className={classnames(css.inputAutoSuggestWrapper, className)}>
			<Input
				{...remainingProps}
				value={localSearchTerm}
				name='autoSuggest'
				type='text'
				onChange={e => {
					const newSearchTerm = e.target.value;
					setQueryProps(prev => ({ ...prev, searchTerm: newSearchTerm }));
					setLocalSearchTerm(newSearchTerm);

					if (newSearchTerm.length > 3 && !isDropdownShown) {
						setDropdownShown(true);
					}
				}}
				placeholder='Search item...'
				autoComplete='off'
				className={css.input}
				ref={buttonRef}
			/>

			{isDropdownShown && selectedData?.length > 0 && (
				<div className={css.autoSuggestionContentArea} ref={dropdownRef}>
					<List
						listFocusFirst={false}
						items={selectedData?.map(item => ({
							title: item[labelPropName] || item[valuePropName].toString(),
							action: () => {
								setQueryProps(prev => ({ ...prev, searchTerm: item[labelPropName] as string }));
								setFieldValue(name, item[valuePropName]);
								setLocalSearchTerm(item[labelPropName]);
								setDropdownShown(false);
								// Trigger parent onSelectOption if provided
								if (onSelectOption) {
									onSelectOption(item[valuePropName]);
								}
							}
						}))}
					/>
				</div>
			)}
		</div>
	);
};
