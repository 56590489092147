import { useQuery } from 'react-query';
import { handleResponse } from './api';
import { useAxios } from 'providers/Axios/AxiosProvider';
import { ProductFinish } from './productFinish';

export type Product = {
	materialNumber: string;
	longText?: string;
	materialDescription?: string;
	productHierarchy?: string;
	productCategoryId?: string;
	categories?: string;
	owningCluster?: string;
	plantLocation?: string;
	ownerName?: string;
	productWarranty?: string;
	taxClassificationMaterial?: string;
	materialGroup2?: string;
	itClusters?: string;
	minimumOrderQuantity?: string;
	imageFileName?: string;
	imageFileLocation?: string;
	imageStatus?: string;
	productUrl?: string;
	productName?: string;
	bulletPoint1?: string;
	bulletPoint2?: string;
	bulletPoint3?: string;
	bulletPoint4?: string;
	bulletPoint5?: string;
	itemHeight?: string;
	itemLength?: string;
	itemDepth?: string;
	productWeight?: string;
	weightUnits?: string;
	dimensionUnits?: string;
	handOrientation?: string;
	handleLength?: string;
	handleMaterials?: string;
	handleType?: string;
	listPrice?: string;
	unitOfMeasure?: 'FT' | 'EA' | 'FREE' | 'CLEAR OPENING FT';
	finish?: string;
	lineItems?: string;
	isDeleted?: false;
	createdBy?: string;
	createdAt?: Date;
	updatedBy?: string;
	updatedAt?: Date;
	deletedBy?: string;
	deletedAt?: string;
	discountedPrice?: number;
};

export type PricingResponse = {
	priceByMasterData?: number;
	priceByFinish?: number;
	discountedPrice?: number;
	paymentTerms?: string;
	finishInformation?: ProductFinish;
};

export type ProductPricingResponse = {
	product: Product;
	productPricing: PricingResponse[];
};

export type ApiProductsResponse = {
	listOfProductsWithPricing: ProductPricingResponse[];
	pageNumber: number;
	pageSize: number;
	totalPages: number;
	totalRecords: number;
};

export type ApiProductsRequestParameters = {
	pageNumber: number;
	pageSize: number;
	glassThickness?: string;
	railHeight?: string;
	railProfile?: string;
	systemType?: string;
	panelWidth?: string;
	productCode?: string; // only one - the ProductCodeId
};

// Gets info about a folder and its immediate children
export const useSearchProduct = (searchString: string, parameters?: ApiProductsRequestParameters) => {
	const axios = useAxios();
	return useQuery(
		['productSearch', `${searchString}`, parameters],
		async () => {
			return handleResponse(
				axios.post<ApiProductsResponse>(
					`/product/search`,
					{ searchString },
					{
						params: {
							...parameters
						}
					}
				)
			);
		},
		{
			keepPreviousData: true
		}
	);
};

export const useGetProduct = (productId: string) => {
	const axios = useAxios();
	return useQuery(['product', `${productId}`], async () => {
		return handleResponse(axios.get<ProductPricingResponse>(`product/materialNumber/${productId}`));
	});
};
