import { ProductPricingResponse } from 'api/product';
import { calculateDisplayedProductPrice } from './calculateDisplayedProductPrice';

export const calculateLineItemPricePerUnit = (
	productInfo: ProductPricingResponse,
	finishNumber?: number,
	width?: number,
	pullHandleFinishNumber?: number,
	clearOpeningWidth?: number
): { status: 'success' | 'error'; value: number } => {
	const isProductAPull =
		productInfo.product?.categories === 'Pulls, Handles, & Locksets' && pullHandleFinishNumber;
	const doesProductUseClearOpeningWidth =
		productInfo.product?.unitOfMeasure === 'CLEAR OPENING FT' && clearOpeningWidth;
	const finishPricing = productInfo.productPricing?.find(productPrice => {
		const finishToCheck = isProductAPull ? pullHandleFinishNumber : finishNumber;

		return productPrice.finishInformation?.finishNumber === finishToCheck;
	});

	if (finishPricing && finishPricing.priceByFinish) {
		// The product using finish when pricing
		const total = calculateDisplayedProductPrice(
			finishPricing.priceByFinish,
			1, // just need the price for 1
			productInfo.product.unitOfMeasure,
			doesProductUseClearOpeningWidth ? clearOpeningWidth : width
		);
		if (total) {
			return {
				status: 'success',
				value: total
			};
		} else {
			return {
				status: 'error',
				value: 0
			};
		}
	} else if (
		productInfo.productPricing?.[0]?.priceByMasterData ||
		productInfo.productPricing?.[0]?.priceByMasterData === 0
	) {
		// priceByMasterData === 0 protects free items and allows them to avoid errors and get tallied
		// the product does not have a finish when pricing
		const total = calculateDisplayedProductPrice(
			productInfo.productPricing[0].priceByMasterData,
			1, // just need the price for 1
			productInfo.product.unitOfMeasure,
			doesProductUseClearOpeningWidth ? clearOpeningWidth : width
		);
		if (total || total === 0) {
			// total === 0 protects free items and allows them to avoid errors and get tallied
			return {
				status: 'success',
				value: total
			};
		} else {
			return {
				status: 'error',
				value: 0
			};
		}
	} else if (productInfo.productPricing?.[0]?.priceByFinish) {
		// the product did not match by a user chosen finish nor a by master data. This can mean that the product does not come in the users chosen finish so lets default to the first one
		const total = calculateDisplayedProductPrice(
			productInfo.productPricing[0].priceByFinish,
			1, // just need the price for 1
			productInfo.product.unitOfMeasure,
			doesProductUseClearOpeningWidth ? clearOpeningWidth : width
		);
		if (total) {
			return {
				status: 'success',
				value: total
			};
		} else {
			return {
				status: 'error',
				value: 0
			};
		}
	} else {
		// ensure the error is set here. All customers should be able to see non discounted prices unless there is an error with master data.
		return {
			status: 'error',
			value: 0
		};
	}
};
