import { ProductPricingResponse } from 'api/product';
import { PricingDisplayOutput, ProductPricingOptions } from 'views/ProductSingleView/ProductSingleView';
import { calculateDisplayedProductPrice } from './calculateDisplayedProductPrice';

export const determinePricing = (
	productPricing: ProductPricingResponse,
	options: ProductPricingOptions = { quantity: 1, width: 0 }
): PricingDisplayOutput => {
	const productUnitOfMeasure = productPricing.product?.unitOfMeasure;
	const quantity = options.quantity;
	const width = options.width;
	const finishNumber = options?.finishNumber;
	if (typeof quantity !== 'number') {
		console.error('Could not find pricing, quantity must be a number');
		return {
			status: 'error',
			discountedPrice: 0,
			discountAmount: 0,
			listPrice: 0
		};
	}
	// Dev Note: finishNumber === 0 is specifically to protect against the finish number 0 from reading as false
	if (finishNumber || finishNumber === 0) {
		const finishPricing = productPricing.productPricing.find(
			productPrice => productPrice.finishInformation.finishNumber === finishNumber
		);

		if (finishPricing && finishPricing.discountedPrice) {
			return {
				status: 'success',
				discountedPrice: finishPricing.discountedPrice
					? calculateDisplayedProductPrice(
							finishPricing.discountedPrice,
							quantity,
							productUnitOfMeasure,
							width
					  )
					: null,
				discountAmount: finishPricing.discountedPrice
					? calculateDisplayedProductPrice(
							finishPricing.priceByFinish - finishPricing.discountedPrice,
							quantity,
							productUnitOfMeasure,
							width
					  )
					: null,
				listPrice: finishPricing.priceByFinish
					? calculateDisplayedProductPrice(
							finishPricing.priceByFinish,
							quantity,
							productUnitOfMeasure,
							width
					  )
					: null
			};
		} else if (finishPricing && finishPricing.priceByFinish) {
			return {
				status: 'success',
				discountedPrice: null,
				discountAmount: null,
				listPrice: finishPricing.priceByFinish
					? calculateDisplayedProductPrice(
							finishPricing.priceByFinish,
							quantity,
							productUnitOfMeasure,
							width
					  )
					: null
			};
		} else {
			console.error(`Could not find pricing for finish number ${finishNumber}`);
			return {
				status: 'error',
				discountedPrice: 0,
				discountAmount: 0,
				listPrice: 0
			};
		}
	} else {
		if (productPricing.productPricing[0].discountedPrice) {
			return {
				status: 'success',
				discountedPrice: calculateDisplayedProductPrice(
					productPricing.productPricing[0].discountedPrice,
					quantity,
					productUnitOfMeasure,
					width
				),
				discountAmount: calculateDisplayedProductPrice(
					productPricing.productPricing[0].priceByMasterData -
						productPricing.productPricing[0].discountedPrice,
					quantity,
					productUnitOfMeasure,
					width
				),
				listPrice: calculateDisplayedProductPrice(
					productPricing.productPricing[0].priceByMasterData,
					quantity,
					productUnitOfMeasure,
					width
				)
			};
		} else if (productPricing.productPricing[0].priceByMasterData) {
			return {
				status: 'success',
				discountedPrice: 0,
				discountAmount: 0,
				listPrice: calculateDisplayedProductPrice(
					productPricing.productPricing[0].priceByMasterData,
					quantity,
					productUnitOfMeasure,
					width
				)
			};
		} else {
			console.error(`Could not find pricing for ${productPricing.product.materialNumber}`);
			return {
				status: 'error',
				discountedPrice: 0,
				discountAmount: 0,
				listPrice: 0
			};
		}
	}
};
