import { useQuery, useMutation, useQueryClient } from 'react-query';
import { handleResponse } from './api';
import { useAxios } from 'providers/Axios/AxiosProvider';
import { LineItem } from './lineItem';
import { OrderComment } from './orderComment';
import { OrderUpload } from './orderUpload';
import { User } from './user';
import { Company } from './company';

export type OrderType = 'stock-cpq' | 'createopening';

export type OrderStatus =
	// for 'Quote' mode
	| 'NotSubmitted'
	| 'Submitted'
	// for 'Order' mode
	| 'InReview'
	| 'OrderPlaced';

// This is also used as the inital values type on the checkout form. If more fields are added this type will need to be updated
export type OrderBaseDetails = {
	poNumber: string;
	shippingMethod: string;
	shippingAccountNumber: string;
	shipToAddressLine1: string;
	shipToAddressLine2: string;
	shipToCity: string;
	shipToState: string;
	shipToZipCode: string;
	shippingTopComment: string;
};

export type Order = {
	id: string;
	orderConfirmationId: number;
	enteredIntoAS400: boolean;
	name: string;
	status: OrderStatus;
	customerId: string; //Guid  // FK to Company;
	company: Company;
	orderType: OrderType;
	submittedOn: Date;
	shipToAddressLine3?: string;
	reviewedById?: string; // FK to User;
	reviewedOn?: Date;
	claimedById?: string; // FK to User;
	reviewedByUser?: User; // [ForeignKey("ReviewedById")]
	claimedByUser: User; //[ForeignKey("ClaimedById")]

	// UI JSON fields - send Json Objects to them and en-code and de-code them
	openingQuestions: string;
	openingSaveStateObj: string;
	// Generic Data info
	isDeleted: boolean;
	createdBy: string;
	createdAt: Date;
	updatedBy?: string;
	updatedAt?: Date;
	deletedBy?: string;
	deletedAt?: Date;
	// Shipping
	shippedOn?: Date;
	// Pricing
	orderTotal?: number;
	subtotal?: number;
	totalDiscounts?: number;

	// nav props
	// company: Company;
	lineItems?: LineItem[];
	orderComments?: OrderComment[];
	orderUploads?: OrderUpload[];
	// claimedByUser: User  = null!; //[ForeignKey("ClaimedById")]
	// reviewedByUser: User; // [ForeignKey("ReviewedById")]
} & OrderBaseDetails;

type ApiOrdersResponse = Order[];

// Update
export const useUpdateOrder = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (order: Partial<Order>) => {
			return handleResponse(axios.put<Partial<Order>>(`/order/${order.id}`, order));
		},
		{
			onSuccess: (data, order) => {
				queryClient.invalidateQueries(['orders']);
				queryClient.invalidateQueries(['ordersActive']);
				queryClient.invalidateQueries(['ordersCompleted']);
				queryClient.invalidateQueries(['order', `${order.id}`]);
				queryClient.invalidateQueries(['ordersQuotes', `${order.company.id}`]);
			}
		}
	);
};

export const useCreateOrderQuote = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (order: Partial<Order & { customerId: string }>): Promise<Order> => {
			return handleResponse(axios.post('order', order));
		},
		{
			onSuccess: (data, order) => {
				queryClient.invalidateQueries(['ordersQuotes', `${order.customerId}`]);
				queryClient.invalidateQueries(['orders']);
				queryClient.invalidateQueries(['ordersActive']);
				queryClient.invalidateQueries(['ordersCompleted']);
			}
		}
	);
};

export const useDuplicateOrder = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (options: {
			order: Order;
			newName: string;
			newPONumber?: string;
			copyShipping?: boolean;
		}): Promise<Order> => {
			console.log('options: ', {
				orderId: options.order.id,
				newName: options.newName,
				...(options.newPONumber ? { newPONumber: options.newPONumber } : {}),
				...(options.copyShipping ? { copyShipping: options.copyShipping } : {})
			});
			return handleResponse(
				axios.post('order/duplicate', {
					orderId: options.order.id,
					newName: options.newName,
					...(options.newPONumber ? { newPONumber: options.newPONumber } : {}),
					...(options.copyShipping ? { copyShipping: options.copyShipping } : {})
				})
			);
		},
		{
			onSuccess: (data, options) => {
				queryClient.invalidateQueries(['ordersQuotes', `${options.order.customerId}`]);
			}
		}
	);
};

// READ
export const useGetAllOrders = () => {
	const axios = useAxios();
	return useQuery(['orders'], async () => {
		return handleResponse(axios.get<ApiOrdersResponse>(`/order`));
	});
};

export const useGetAllActiveOrders = (status: string, customerServiceRepUserId: string) => {
	const axios = useAxios();
	return useQuery(['ordersActive', status, customerServiceRepUserId], async () => {
		return handleResponse(
			axios.get<ApiOrdersResponse>(`/order/active`, {
				params: {
					status,
					customerServiceRepUserId
				}
			})
		);
	});
};

export const useGetAllCompletedOrders = (status: string, customerServiceRepUserId: string) => {
	const axios = useAxios();
	return useQuery(['ordersCompleted', status, customerServiceRepUserId], async () => {
		return handleResponse(
			axios.get<ApiOrdersResponse>(`/order/completed`, {
				params: {
					status,
					customerServiceRepUserId
				}
			})
		);
	});
};

export const useGetOrderHistoryByCompanyId = (companyId: string) => {
	const axios = useAxios();
	return useQuery(['ordersHistory', `${companyId}`], async () => {
		return handleResponse(axios.get<ApiOrdersResponse>(`/order/history/company/${companyId}`));
	});
};

export const useGetOrderQuotesByCompanyId = (companyId: string) => {
	const axios = useAxios();
	return useQuery(['ordersQuotes', `${companyId}`], async () => {
		return handleResponse(axios.get<ApiOrdersResponse>(`/order/quotes/company/${companyId}`));
	});
};

export const useGetOrderById = (id: string) => {
	const axios = useAxios();
	return useQuery(['order', `${id}`], async () => {
		return handleResponse(axios.get<Order>(`/order/${id}`));
	});
};

export const useDeleteQuoteById = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (options: { id: string; companyId: string }) => {
			return handleResponse(axios.delete<string>(`/order/${options.id}`));
		},
		{
			onSuccess: (data, options) => {
				queryClient.invalidateQueries(['ordersQuotes', `${options.companyId}`]);
				queryClient.invalidateQueries(['ordersActive', 'InReview']);
			}
		}
	);
};
