import React, { useEffect, useState, useRef, PropsWithChildren } from 'react';
import css from './DropdownList.module.scss';
import classes from 'classnames';
import { TDropdownItem } from 'common/types/TDropdownItem';
import { TToggle } from 'common/hooks/useToggle';
import { Dropdown } from 'components/Dropdown';
import { IDropdownProps } from 'components/Dropdown';
import { RemoveProp } from 'common/types/TypeHelpers';
import { Link } from 'components/Link';
import { Button } from 'components/Button';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';

interface IListProps {
	items: TDropdownItem[];
	toggle?: TToggle;
	listFocusFirst?: boolean;
}

interface IDropdownItemProps {
	index: number;
	dropdownItem: TDropdownItem;
	handleKeydown: (event: React.KeyboardEvent<any>, dropdownItem: TDropdownItem) => void;
	setRefByIndex: (ref: React.MutableRefObject<HTMLElement>, index: number) => void;
	onClick?: () => void;
}

const DropdownItem: React.FC<IDropdownItemProps> = props => {
	const { index, dropdownItem, handleKeydown, setRefByIndex, onClick } = props;
	const ref = useRef(null);

	useEffect(() => {
		setRefByIndex(ref, index);
	}, [index, setRefByIndex]);

	switch (typeof dropdownItem.action) {
		case 'function':
			return (
				<li key={index}>
					<Button
						ref={ref}
						onClick={() => {
							onClick && onClick();
							typeof dropdownItem.action === 'function' && dropdownItem.action();
						}}
						onKeyDown={event => handleKeydown(event, dropdownItem)}
						type='link'
						classNames={{ buttonContent: css.buttonContent }}
					>
						{dropdownItem.title}
					</Button>
				</li>
			);
		case 'string':
			return (
				<li key={index}>
					<Link
						ref={ref}
						to={dropdownItem.action}
						onClick={() => {
							onClick && onClick();
						}}
						onKeyDown={event => handleKeydown(event, dropdownItem)}
						classNames={{ buttonContent: css.buttonContent }}
					>
						{dropdownItem.title}
					</Link>
				</li>
			);
	}
};

export const List: React.FC<IListProps> = props => {
	const { items, toggle, listFocusFirst = true } = props;

	const [refs, setRefs] = useState([]);

	const setRefByIndex = (ref: React.MutableRefObject<HTMLElement>, index: number) => {
		refs[index] = ref;
		setRefs(refs);
	};

	const selectItem = (dropdownitem: TDropdownItem) => {
		const index = items.indexOf(dropdownitem);
		refs[index].current.focus();
	};

	const previousItem = (dropdownitem: TDropdownItem) => {
		const index = items.indexOf(dropdownitem);
		if (index > 0) {
			selectItem(items[index - 1]);
		} else {
			selectItem(items[items.length - 1]);
		}
	};

	const nextItem = (dropdownitem: TDropdownItem) => {
		const index = items.indexOf(dropdownitem);
		if (index < items.length - 1) {
			selectItem(items[index + 1]);
		} else {
			selectItem(items[0]);
		}
	};

	const handleKeydown = (event: React.KeyboardEvent<HTMLAnchorElement>, dropdownitem: TDropdownItem) => {
		if (event.which === 38) {
			event.preventDefault();
			previousItem(dropdownitem);
		} else if (event.which === 40) {
			event.preventDefault();
			nextItem(dropdownitem);
		}
	};

	useEffect(() => {
		if (listFocusFirst) {
			refs[0].current.focus();
		}
	}, [refs]); // eslint-disable-line

	return (
		<ul className={classes(css.dropdownlist)}>
			{items.map((item: TDropdownItem, index: number) => (
				<DropdownItem
					key={index}
					onClick={() => {
						toggle && toggle.toggle();
					}}
					handleKeydown={handleKeydown}
					dropdownItem={item}
					index={index}
					setRefByIndex={setRefByIndex}
				></DropdownItem>
			))}
		</ul>
	);
};

type IDropdownListProps = RemoveProp<IListProps & IDropdownProps, 'DropdownContent'> & {
	ContentTop?: React.ComponentType<any>;
};

export const DropdownList: React.FC<PropsWithChildren<IDropdownListProps>> = props => {
	const { items, children, ContentTop } = props;
	const dropdownProps = {
		...removePropertiesFromObjects(['items', 'ContentTop'], props),
		DropdownContent: (props: any) => (
			<>
				{ContentTop && <ContentTop />}
				<List {...props} items={items}></List>
			</>
		)
	};

	return <Dropdown {...dropdownProps}>{children}</Dropdown>;
};
