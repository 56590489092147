import React, { useEffect, useState } from 'react';
import css from './QuoteEditName.module.scss';
import classes from 'classnames';
import { useSingleQuote } from 'providers/SingleQuote';
import { useUpdateOrder } from 'api/order';

export const QuoteEditName = () => {
	const { singleQuoteData, setSingleQuoteData } = useSingleQuote();
	const { mutateAsync: updateOrder } = useUpdateOrder();

	const [isEditMode, setIsEditMode] = useState(false);
	const [title, setTitle] = useState<string | undefined>(undefined);

	// Initialize title only when singleQuoteData is loaded
	useEffect(() => {
		if (singleQuoteData?.name && title === undefined) {
			setTitle(singleQuoteData.name);
		}
	}, [singleQuoteData?.name, title]);

	const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(e.target.value);
	};

	const handleBlur = async () => {
		setIsEditMode(false);
		if (title !== undefined && singleQuoteData) {
			const updatedQuote = { ...singleQuoteData, name: title };
			await updateOrder(updatedQuote);
			setSingleQuoteData(updatedQuote);
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			handleBlur();
		}
	};

	// Show loading indicator until title is initialized
	if (title === undefined) {
		return <div className={css.quoteEditName}>Loading...</div>;
	}

	return (
		<div className={css.quoteEditName}>
			{isEditMode ? (
				<input
					className={css.titleInput}
					value={title}
					onChange={handleTitleChange}
					onBlur={handleBlur}
					onKeyDown={handleKeyDown}
					autoFocus
				/>
			) : (
				<h1 className={classes('h3')} onClick={() => setIsEditMode(true)}>
					{title}
				</h1>
			)}
		</div>
	);
};
