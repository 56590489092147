import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { useAuth } from 'providers/Auth';
import css from './CustomerQuoteDetailView.module.scss';
import classes from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from 'components/Container';
import { Spinner } from 'components/Spinner';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { Link } from 'components/Link';
import { OrderType, useGetOrderById, useDeleteQuoteById } from 'api/order';
import { QuoteDetailLineItems } from '../common/QuoteDetailLineItems/QuoteDetailLineItems';
import { QuoteDetailMessages } from '../common/QuoteDetailMessages/QuoteDetailMessages';
import { QuoteDetailEditForm } from '../common/QuoteDetailEditForm/QuoteDetailEditForm';
import { QuoteDetails } from '../common/QuoteDetails/QuoteDetails';
import { QuoteAdditionalDetails } from '../common/QuoteAdditionalDetails/QuoteAdditionalDetails';
import { QuoteDetailSidebarStatusAndPrice } from '../common/QuoteDetailSidebarStatusAndPrice/QuoteDetailSidebarStatusAndPrice';
import { Button } from 'components/Button';
import { Panel } from 'components/Panel';
import { ErrorView } from 'views/views';
import { Toggle } from 'components/Toggle/Toggle';
import { DropdownList } from 'components/DropdownList/DropdownList';
import { TDropdownItem } from 'common/types/TDropdownItem';
import { useModal } from 'providers/Modal/ModalProvider';
import { useSingleQuote } from 'providers/SingleQuote/SingleQuoteProvider';
import { CreateOpeningSavedLocationState } from 'components/CreateOpening/common/types';
import { QuoteUploads } from '../common/QuoteUploads/QuoteUploads';
import { Alert } from 'components/Alert/Alert';
import { DuplicateOrderButton } from 'components/DuplicateOrderButton/DuplicateOrderButton';
import { Form, Formik } from 'formik';
import { Input } from 'components/Form/Input/Input';
import { FormFooter } from 'components/Form/FormFooter/FormFooter';
import { formatAsUSD } from 'common/utils/formatAsUSD';
import { AutoSuggestQueryParams, InputAutoSuggest } from 'components/Form/InputAutoSuggest';
import { ApiProductsResponse, useGetProduct, useSearchProduct } from 'api/product';
import { UseQueryResult } from 'react-query';
import { PricingDisplayOutput, ProductPricingOptions } from 'views/ProductSingleView/ProductSingleView';
import { Select } from 'components/Form/Select/Select';
import { determinePricing } from 'common/utils/determineProductPrice';
import { renderFinishOptions } from 'common/utils/renderFinishOptions';
import { useCreateOrderQuoteLineItem } from 'api/lineItem';
import { calculateLineItemPricePerUnit } from 'common/utils/calculateLineItemPricePerUnit';
import { calculateLineItemDiscountedPricePerUnit } from 'common/utils/calculateLineItemDiscountedPricePerUnit';
import { QuoteEditName } from '../common/QuoteEditName/';

const initialProductOptions: ProductPricingOptions = {
	finishNumber: null,
	width: 0,
	quantity: 1
};

const initialPricingDisplayOutput: PricingDisplayOutput = {
	status: 'success',
	discountedPrice: 0,
	discountAmount: 0,
	listPrice: 0
};

export interface CustomerQuoteDetailViewProps {
	showFooter?: boolean;
	mode?: OrderType;
}

export const CustomerQuoteDetailView: React.FC<CustomerQuoteDetailViewProps> = props => {
	const { showFooter = true, mode = 'stock-cpq' } = props;
	const { isLoading: isAuthLoading, getCompanyId } = useAuth();
	const {
		isQuoteDataValid,
		singleQuoteData,
		setSingleQuoteData,
		showSaveMessage,
		setIsQuoteReadyToSubmit
	} = useSingleQuote();
	const [showDiscount, setShowDiscount] = useState(true);
	const [quoteSaver, setQuoteSaver] = useState<() => Promise<void> | null>();
	const navigate = useNavigate();
	const { createModal, removeModal } = useModal();
	const { orderId } = useParams<{ orderId: string }>();
	const skipSubmitWarning = localStorage.getItem('skipSubmitWarning'); // should equal string 'skip'

	const {
		data: getOrderData,
		isLoading: isGetOrderLoading,
		error: getOrderError
	} = useGetOrderById(orderId);

	const { mutateAsync: deleteQuote } = useDeleteQuoteById();

	// Set the context with the updated order data.
	useEffect(() => {
		if (!isGetOrderLoading) {
			setSingleQuoteData(getOrderData);
		}
	}, [isGetOrderLoading, setSingleQuoteData, getOrderData]);

	const handleSaveOrder = async (event: any) => {
		await quoteSaver();
	};

	const handleSubmitOrder = useCallback(
		async (event: any) => {
			if (skipSubmitWarning === 'skip') {
				if (isQuoteDataValid) {
					setIsQuoteReadyToSubmit(true);
					await quoteSaver();
				} else {
					setIsQuoteReadyToSubmit(false);
					await quoteSaver();
				}
			} else {
				createModal({
					sourceEvent: event,
					heading: 'Are you ready to submit your quote?',
					Content: () => {
						return (
							<>
								<p>
									Once submitting your quote you will not be able to change any details or line items. If
									any updates need to be made then use the messages below on your order.{' '}
								</p>
								<Button
									type={'link'}
									onClick={() => {
										localStorage.setItem('skipSubmitWarning', 'skip');
									}}
								>
									Don't show me this again.
								</Button>
							</>
						);
					},
					primaryButtonLabel: 'Confirm submit',
					primaryButtonAction: async () => {
						if (isQuoteDataValid) {
							setIsQuoteReadyToSubmit(true);
							await quoteSaver();
						} else {
							setIsQuoteReadyToSubmit(false);
							await quoteSaver();
						}
						removeModal();
					}
				});
			}
		},
		[quoteSaver, createModal, isQuoteDataValid, removeModal, setIsQuoteReadyToSubmit, skipSubmitWarning]
	);

	const handleDeleteQuote = useCallback(
		async (quoteId: string, companyId: string, event: any) => {
			createModal({
				sourceEvent: event,
				heading: 'Are you sure?',
				Content: () => {
					return (
						<>
							<p>Once the quote is deleted this action can not be un-done.</p>
						</>
					);
				},
				primaryButtonLabel: 'Confirm delete',
				primaryButtonAction: async () => {
					await deleteQuote({ id: quoteId, companyId });
					navigate('/quotes', {
						state: {
							bannerSuccess: {
								message: 'Your quote was successfully removed.'
							}
						}
					});
					removeModal();
				}
			});
		},
		[quoteSaver, createModal, removeModal] // eslint-disable-line
	);

	// Print Options
	const printDropdownItems: TDropdownItem[] = [
		{
			title: 'Show Discount',
			action: () => {
				setShowDiscount(true);
				window.print();
			}
		},
		{
			title: 'No Discount',
			action: () => {
				setShowDiscount(false);
				setTimeout(() => {
					window.print();
				}, 100);
			}
		}
	];

	const useQuery = ({ searchTerm, pageNumber, pageSize }: AutoSuggestQueryParams): UseQueryResult => {
		return useSearchProduct(searchTerm, { pageNumber, pageSize });
	};

	const [productId, setProductId] = useState<string>(undefined);

	const [productOptions, setProductOptions] = useState<ProductPricingOptions>(initialProductOptions);

	const [productDisplayedPrices, setProductDisplayedPrices] = useState<PricingDisplayOutput>(
		initialPricingDisplayOutput
	);

	const { mutateAsync: createQuoteLineItem, error: createQuoteLineItemError } =
		useCreateOrderQuoteLineItem();

	const { data: productData, isLoading: productIsLoading } = useGetProduct(productId);

	// Initial price setting so that the first option when a new line item is selected can load in.
	useEffect(() => {
		if (
			!productIsLoading &&
			productData &&
			productData.productPricing &&
			productData.productPricing.length > 1
		) {
			const firstFinish = productData?.productPricing[0]?.finishInformation?.finishNumber;
			const newProductPricingOptions = {
				...productOptions,
				finishNumber: firstFinish
			};
			setProductOptions(newProductPricingOptions);
			const prices = determinePricing(productData, newProductPricingOptions);
			setProductDisplayedPrices({
				...prices
			});
		}
	}, [productData, productIsLoading]); // eslint-disable-line

	const handleProductOptionsChange = (propName: string, propValue: string) => {
		const newProductPricingOptions = {
			...productOptions,
			[propName]: propName === 'width' ? propValue : parseFloat(propValue)
		};

		setProductOptions(newProductPricingOptions);
		const prices = determinePricing(productData, newProductPricingOptions);
		setProductDisplayedPrices({
			...prices
		});
	};

	const canEdit = useMemo(() => {
		return singleQuoteData && singleQuoteData.status && singleQuoteData.status === 'NotSubmitted';
	}, [singleQuoteData]);

	// Loading & Errors
	if (getOrderError) {
		return <ErrorView isInAppFrame={true} />;
	}
	if (isAuthLoading || isGetOrderLoading || !singleQuoteData) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<>
			<PageFrame
				title={singleQuoteData?.name ?? 'New Order'}
				isContentManagedTitle={true}
				showFooter={showFooter}
			>
				<Container className={classes(css.appframe, { [css.width100]: mode === 'createopening' })}>
					{/* Left side content */}
					<div className={classes(css.main)}>
						{/* Top Title & Pricing toggle */}
						<div className={classes(css.flexContainer, css.justifySpaceBetween)}>
							{/* Top title and back button */}
							<div style={{ flexGrow: 1 }}>
								{/* Order Name */}
								<QuoteEditName />
								{/* Back to all Quotes */}
							</div>
							{/* View pricing toggle */}
							<div className={classes(css.flexContainer, css.hideFromPrint)}>
								<p className={css.viewPricingLabel}>{'VIEW PRICING: '} </p>{' '}
								<Toggle
									checked={showDiscount}
									label={''}
									onChange={event => {
										setShowDiscount(event.currentTarget.checked);
									}}
								/>
							</div>
						</div>

						{mode === 'stock-cpq' && (
							<Button
								type={'link'}
								className={css.hideFromPrint}
								iconLeft={{
									type: 'CaretLeft',
									color: 'blue'
								}}
								onClick={() => {
									navigate(-1);
								}}
							>
								{singleQuoteData?.status === 'OrderPlaced' ? 'Back to all orders' : 'Back to all quotes'}
							</Button>
						)}

						{/* Disclaimers */}
						<div className={css.orderDisclaimer}>
							<span className={classes(css.boldText, css.italicText)}>
								Please note pricing on quotes is only valid for 30 days.{' '}
							</span>
							<span className={css.italicText}>
								If more than 30 days have passed your estimated total could change.{' '}
							</span>

							<span className={classes(css.boldText, css.italicText)}>
								Got a question or need help?{' '}
							</span>
							<span className={css.italicText}>
								Leave us a message below and we'll get back to you or{' '}
								<Link
									to={'mailto:glasscustserv.us@dormakaba.com'}
									className={css.inlineLink}
									classNames={{ buttonContent: css.inlineLink }}
									external={true}
								>
									{' '}
									contact us.
								</Link>
							</span>
						</div>

						{/* Formik form for the order OR Sections of labels */}
						{showSaveMessage && showSaveMessage === 'success' && (
							<Alert type={'success-light'}>Your quote was saved successfully.</Alert>
						)}
						{canEdit ? (
							<QuoteDetailEditForm setFormSubmitter={setQuoteSaver} companyId={getCompanyId()} />
						) : (
							<QuoteDetails orderData={singleQuoteData} />
						)}

						<QuoteDetailLineItems
							orderId={orderId}
							orderData={singleQuoteData}
							showDiscount={showDiscount}
							canEditLineItems={canEdit}
						/>

						{canEdit && (
							<Panel className={css.addProductToOrder}>
								<Formik
									initialValues={{
										materialNumber: '',
										quantity: '1'
									}}
									onSubmit={async (data, { setSubmitting, resetForm }) => {
										setSubmitting(true);
										try {
											const priceResponse = calculateLineItemPricePerUnit(
												productData,
												productOptions.finishNumber,
												productOptions.width
											);

											const discountResponse = calculateLineItemDiscountedPricePerUnit(
												productData,
												productOptions.finishNumber,
												productOptions.width
											);

											await createQuoteLineItem({
												orderId: singleQuoteData.id,
												materialNumber: data.materialNumber,
												title: productData.product.productName,
												pricePerUnit: priceResponse.value,
												discountedPricePerUnit: discountResponse.value, // customer service is responsible for making sure the discount is applied in pricePerUnit for adjustments so always set to null
												quantity: parseInt(data.quantity),
												lineItemDetails: (() => {
													return JSON.stringify({
														...(productOptions.width > 0 ? { width: `${productOptions.width}ft` } : {}),
														...(productOptions.finishNumber
															? { finish: `${productOptions.finishNumber}` }
															: {})
													});
												})()
											});
											setSubmitting(false);
											if (!createQuoteLineItemError) {
												// Reset form data for next add
												resetForm();
												setProductOptions(initialProductOptions);
												setProductDisplayedPrices(initialPricingDisplayOutput);
											}
										} catch {
											setSubmitting(false);
										}
									}}
									validationSchema={yup.object().shape({
										materialNumber: yup.string().required('Material number is required'),
										quantity: yup.string().required('Quantity required')
									})}
								>
									{props => {
										return (
											<>
												{createQuoteLineItemError && !props.isSubmitting && (
													<Alert type='danger-light'>
														There was an error adding a line item to this order, please try again.
													</Alert>
												)}
												<Form>
													<h2 className={css.panelTitle}>Add Additional Product To Order</h2>
													<div className={classes(css.flexContainer, css.alignFlexStart)}>
														<div className={classes(css.flexContainer, css.alignFlexStart, css.stacked)}>
															<InputAutoSuggest
																label={'Product'}
																name={'materialNumber'}
																className={css.productAttribute}
																formikProps={props}
																useQuery={useQuery}
																dataSelector={(data: ApiProductsResponse) => {
																	return data?.listOfProductsWithPricing?.map(
																		productData => productData.product
																	);
																}}
																onSelectOption={(value: string) => {
																	setProductId(value);
																}}
																labelPropName={'productName'}
																valuePropName={'materialNumber'}
															/>
															{props.values.materialNumber !== '' &&
																productData &&
																productData.product &&
																(productData.product.unitOfMeasure === 'FT' ||
																	productData.product.unitOfMeasure === 'CLEAR OPENING FT') && (
																	<Input
																		label={'Width (feet)'}
																		name={'width'}
																		className={css.productAttribute}
																		value={productOptions.width}
																		onChange={event =>
																			handleProductOptionsChange('width', event.target.value)
																		}
																		errorMessage={
																			productOptions.width * 0 !== 0 ? 'width must be a number' : null
																		}
																		onBlur={() => {
																			//@ts-ignore
																			if (productOptions.width === '' || isNaN(productOptions.width)) {
																				setProductOptions({
																					...productOptions,
																					width: 1
																				});
																			}
																		}}
																	/>
																)}
															{props.values.materialNumber !== '' &&
																productData &&
																productData.productPricing &&
																productData.productPricing.length > 1 && (
																	<Select
																		label={'Finish'}
																		name={'finish'}
																		value={productOptions.finishNumber}
																		onChange={event =>
																			handleProductOptionsChange('finishNumber', event.target.value)
																		}
																	>
																		{renderFinishOptions(productData.productPricing)}
																	</Select>
																)}
															{/* The fields above are here as an example but based on the selected product ID these fields will hide or show */}
														</div>

														<Input
															type={'number'}
															name={'quantity'}
															className={classes(css.smallFlex, css.flexItem)}
															min={1}
															formikProps={props}
															label={'Quantity'}
														/>
														<div className={classes(css.mediumFlex, css.flexItem)}>
															<span className={css.inputLabel}>Total</span>
															{showDiscount &&
															productDisplayedPrices.discountedPrice !== 0 &&
															productDisplayedPrices?.listPrice * parseInt(props.values.quantity) -
																productDisplayedPrices?.discountedPrice *
																	parseInt(props.values.quantity) !==
																0 ? (
																<>
																	<span>
																		{formatAsUSD(
																			productDisplayedPrices?.discountedPrice *
																				parseInt(props.values.quantity),
																			true
																		)}
																	</span>
																	<div className={css.subText}>
																		<span>You Save: </span>{' '}
																		{formatAsUSD(
																			productDisplayedPrices?.listPrice *
																				parseInt(props.values.quantity) -
																				productDisplayedPrices?.discountedPrice *
																					parseInt(props.values.quantity),
																			true
																		)}{' '}
																		<span>after discount</span>
																	</div>
																</>
															) : (
																<span>
																	{formatAsUSD(
																		productDisplayedPrices?.listPrice * parseInt(props.values.quantity),
																		true
																	)}
																</span>
															)}
														</div>
													</div>
													<FormFooter>
														<Button
															type={'primary'}
															htmlType={'submit'}
															className={css.addProductToOrderButton}
															isLoading={props.isSubmitting}
														>
															Add product to order
														</Button>
													</FormFooter>
												</Form>
											</>
										);
									}}
								</Formik>
							</Panel>
						)}

						{/* Additional Details */}
						{singleQuoteData?.orderType === 'createopening' && singleQuoteData?.openingSaveStateObj && (
							<QuoteAdditionalDetails orderData={singleQuoteData} />
						)}

						{/* If order type is create an opening or has uploads */}
						{singleQuoteData &&
							singleQuoteData.orderUploads &&
							singleQuoteData.orderUploads.length > 0 && <QuoteUploads orderData={singleQuoteData} />}

						{/* Messages */}
						<QuoteDetailMessages orderId={orderId} orderData={singleQuoteData} />
					</div>

					{/* right side content */}
					<div className={classes(css.rightSidePanel)}>
						<div className={classes(css.flexContainer, css.hideFromPrint, css.rightSidePanelControls)}>
							<DuplicateOrderButton type={'link'} orderToDuplicate={singleQuoteData}>
								Duplicate Quote
							</DuplicateOrderButton>
							{singleQuoteData?.status !== 'OrderPlaced' && (
								<>
									<span>|</span>
									<Button
										type={'link'}
										className={css.dangerLink}
										onClick={event => {
											handleDeleteQuote(singleQuoteData.id, singleQuoteData.company.id, event);
										}}
									>
										Delete Quote
									</Button>
								</>
							)}
						</div>

						{/* Print Page Button  */}
						<DropdownList
							className={classes(css.printWrapper, css.hideFromPrint)}
							buttonClassName={css.printButton}
							iconRight={{
								type: 'CaretDown',
								color: 'white'
							}}
							size='small'
							items={printDropdownItems}
						>
							Print Quote
						</DropdownList>

						<QuoteDetailSidebarStatusAndPrice orderData={singleQuoteData} showDiscount={showDiscount} />

						{singleQuoteData?.status === 'NotSubmitted' && (
							<Panel className={css.orderControl}>
								{singleQuoteData?.orderType === 'createopening' && mode === 'stock-cpq' ? (
									<Button
										type='primary'
										className={classes(css.submit, css.hideFromPrint)}
										onClick={event => {
											const createOpeningState: CreateOpeningSavedLocationState = JSON.parse(
												singleQuoteData?.openingSaveStateObj
											);
											navigate(`/createopening/${singleQuoteData.id}`, {
												state: {
													...createOpeningState
												}
											});
										}}
									>
										Continue to Order
									</Button>
								) : (
									<Button
										type='primary'
										className={classes(css.submit, css.hideFromPrint)}
										onClick={event => {
											event.persist();
											handleSubmitOrder(event);
										}}
									>
										Submit
									</Button>
								)}
								<Button
									type={'link'}
									className={css.saveOpening}
									onClick={async event => {
										handleSaveOrder(event);
									}}
								>
									Save for later
								</Button>
							</Panel>
						)}
					</div>
				</Container>
			</PageFrame>
		</>
	);
};
