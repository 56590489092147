import React from 'react';
import { PricingResponse } from 'api/product';
import { labelToKey } from './labelToKey';

export const renderFinishOptions = (pricingResponses: PricingResponse[]) => {
	const finishList = pricingResponses.map(x => x.finishInformation);
	return (
		<>
			{finishList.map(finish => (
				<option key={labelToKey(finish.finishNumber.toString())} value={finish.finishNumber}>
					{finish.finishNumber} - {finish.description}
				</option>
			))}
		</>
	);
};
