import { ProductPricingResponse } from 'api/product';
import { calculateDisplayedProductPrice } from './calculateDisplayedProductPrice';

export const calculateLineItemDiscountedPricePerUnit = (
	productInfo: ProductPricingResponse,
	finishNumber?: number,
	width?: number,
	pullHandleFinishNumber?: number,
	clearOpeningWidth?: number
): { status: 'success' | 'error'; value: number } => {
	const isProductAPull =
		productInfo.product?.categories === 'Pulls, Handles, & Locksets' && pullHandleFinishNumber;
	const doesProductUseClearOpeningWidth =
		productInfo.product?.unitOfMeasure === 'CLEAR OPENING FT' && clearOpeningWidth;
	const finishPricing = productInfo.productPricing?.find(productPrice => {
		const finishToCheck = isProductAPull ? pullHandleFinishNumber : finishNumber;
		return productPrice.finishInformation?.finishNumber === finishToCheck;
	});

	if (finishPricing && finishPricing.discountedPrice) {
		// The product using finish when pricing
		const total = calculateDisplayedProductPrice(
			finishPricing.discountedPrice,
			1, // just need the price for 1
			productInfo.product.unitOfMeasure,
			doesProductUseClearOpeningWidth ? clearOpeningWidth : width
		);
		if (total) {
			return {
				status: 'success',
				value: total
			};
		} else {
			return {
				status: 'error',
				value: total
			};
		}
	} else if (productInfo.productPricing?.[0]?.discountedPrice) {
		const total = calculateDisplayedProductPrice(
			productInfo.productPricing[0].discountedPrice,
			1, // just need the price for 1
			productInfo.product.unitOfMeasure,
			doesProductUseClearOpeningWidth ? clearOpeningWidth : width
		);
		if (total) {
			return {
				status: 'success',
				value: total
			};
		} else {
			return {
				status: 'error',
				value: total
			};
		}
	} else {
		// no need to return error here cause some customers might not get discounts on that line item.
		return {
			status: 'success',
			value: 0
		};
	}
};
