import React, { useEffect, useState } from 'react';
import css from './CustomerServiceActiveOrdersView.module.scss';
import { useLocation } from 'react-router';
import { useAuth } from 'providers/Auth';
import queryString from 'query-string';
import { Panel } from 'components/Panel';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { TTableHeader } from 'components/Table';
import { Link } from 'components/Link';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import {
	TableController,
	useTableProperties,
	ICurrentTableValues
} from 'components/Table/TableController';
import { UseQueryResult } from 'react-query';
import { Order, useGetAllActiveOrders } from 'api/order';
import { useGetUsersByRole } from 'api/user';
import { formatDate } from 'common/utils/formatDate';
import { formatAsUSD } from 'common/utils/formatAsUSD';
import { Select } from 'components/Form/Select/Select';
import { searchTableColumns } from 'common/utils/searchTableColumns';
import { useBanner } from 'components/Banner/BannerProvider';

type TCSOrderFilterOptions = {
	customerServiceRepUserId?: string;
};

export const CustomerServiceActiveOrdersView = () => {
	const { isLoading: isAuthLoading } = useAuth();
	const location = useLocation();
	const filtersSetByRouter = queryString.parse(location?.search);
	const { data: customerServiceUsersData } = useGetUsersByRole('Customer Service');

	const { createBanner } = useBanner();

	useEffect(() => {
		const bannerSuccess = (location.state as { bannerSuccess: { message: string } })?.bannerSuccess;
		if (bannerSuccess) {
			createBanner(<>{bannerSuccess.message}</>);
		}
	}, [location, createBanner]);

	const [filterOptions, setFilterOptions] = useState<TCSOrderFilterOptions>({
		...filtersSetByRouter
	});

	const tableBaseRouteActive = '/cs/orders/active/';
	const [tableProperties, setTableProperties] = useTableProperties(tableBaseRouteActive);

	const useQuery = (): UseQueryResult => {
		const customerServiceRepId = filterOptions.customerServiceRepUserId
			? filterOptions.customerServiceRepUserId
			: null;

		return useGetAllActiveOrders('InReview', customerServiceRepId);
	};

	const dataSelector = (tableValues: ICurrentTableValues) => (orders: Order[]) => {
		return orders?.filter(order => {
			if (tableValues.searchQuery) {
				return searchTableColumns(tableValues.searchQuery, order, headers);
			}

			return true;
		});
	};

	const headers: TTableHeader[] = [
		{
			label: 'Confirmation Number',
			property: 'orderConfirmationId'
		},
		{
			label: 'Order Type',
			property: 'orderType'
		},
		{
			label: 'PO Number',
			property: 'poNumber'
		},
		{
			label: 'Customer Name',
			property: 'companyName',
			renderFunction: (data: Order) => data?.company?.name ?? ''
		},
		{
			label: 'Order Date',
			property: 'submittedOn',
			renderFunction: (data: Order) =>
				data?.submittedOn
					? formatDate(data.submittedOn, true, true)
					: formatDate(data.createdAt, true, true)
		},
		{
			label: 'Claimed By',
			property: 'claimedByUserName',
			renderFunction: (data: Order) =>
				data?.claimedByUser
					? data?.claimedByUser?.firstName + ' ' + data?.claimedByUser?.lastName
					: 'Unclaimed'
		},
		{
			label: 'Total',
			property: 'orderTotal',
			renderFunction: (data: Order) => (data.orderTotal ? formatAsUSD(data.orderTotal, true) : '')
		},
		{
			label: 'Manage',
			property: 'manage',
			renderFunction: (data: Order) => <Link to={`/cs/orders/active/order/${data.id}`}>Manage</Link>
		}
	];

	// LOADING...
	if (isAuthLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<>
			<PageFrame title={'Orders'} HTMLTitle={'Dashboard'}>
				<Section>
					<Container>
						<Panel title={''} hasPadding={false}>
							<TableController
								headers={headers}
								baseRoute={tableBaseRouteActive}
								itemsPerPage={10}
								useQuery={useQuery}
								isUrlUpdated={true}
								hasPagination={true}
								emptyMessage={'No orders found!'}
								dataSelector={dataSelector(tableProperties)}
								setCurrentTableValues={setTableProperties}
								totalCountSelector={(data: any) => data?.length}
								hasSearch={true}
								searchPlaceholder={'Search PO or Confirmation Number'}
								tableHandlesPaginatingData={true}
								queryStringObject={filterOptions}
								additionalActions={[
									<div className={css.displayFlex}>
										{/* CLAIMED BY USER */}
										<div>
											<Select
												label=''
												isLabelVisible={false}
												name='tableFilterClaimedByUser'
												onChange={(event: any) => {
													setFilterOptions({
														...filterOptions,
														customerServiceRepUserId: event.target.value
													});
												}}
												value={filterOptions.customerServiceRepUserId}
												className={css.customerServiceUserFilter}
											>
												<option value=''>All</option>
												{customerServiceUsersData?.map(customerServiceUser => (
													<option value={customerServiceUser.userId} key={customerServiceUser.userId}>
														{customerServiceUser.firstName} {customerServiceUser.lastName}
													</option>
												))}
											</Select>
										</div>
									</div>
								]}
							/>
						</Panel>
					</Container>
				</Section>
			</PageFrame>
		</>
	);
};
